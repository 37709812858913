define("boondmanager/models/notification", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable", "moment"], function (_exports, _base, _emberData, _emberDataCopyable, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.NOTIFICATION_TYPE_RESPONSE = _exports.NOTIFICATION_TYPE_AUTOTIMESHEET = _exports.NOTIFICATION_TYPE_AUTOEXPENSE = _exports.NOTIFICATION_STATE_READ = _exports.NOTIFICATION_STATE_NEW = _exports.NOTIFICATION_CATEGORY_THREAD = _exports.NOTIFICATION_CATEGORY_CORPORATE = _exports.NOTIFICATION_CATEGORY_ACTIVITY = void 0;
  var NOTIFICATION_STATE_READ = _exports.NOTIFICATION_STATE_READ = 'read';
  var NOTIFICATION_STATE_NEW = _exports.NOTIFICATION_STATE_NEW = 'new';
  var NOTIFICATION_CATEGORY_ACTIVITY = _exports.NOTIFICATION_CATEGORY_ACTIVITY = 'activity';
  var NOTIFICATION_CATEGORY_THREAD = _exports.NOTIFICATION_CATEGORY_THREAD = 'thread';
  var NOTIFICATION_CATEGORY_CORPORATE = _exports.NOTIFICATION_CATEGORY_CORPORATE = 'corporate';
  var NOTIFICATION_TYPE_RESPONSE = _exports.NOTIFICATION_TYPE_RESPONSE = 'response';
  var NOTIFICATION_TYPE_AUTOTIMESHEET = _exports.NOTIFICATION_TYPE_AUTOTIMESHEET = 'autotimesheet';
  var NOTIFICATION_TYPE_AUTOEXPENSE = _exports.NOTIFICATION_TYPE_AUTOEXPENSE = 'autoexpense';
  var Notification = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['dependsOn']
    }),
    moment: Ember.inject.service(),
    state: _emberData.default.attr('string'),
    creationDate: _emberData.default.attr('moment'),
    title: _emberData.default.attr('string'),
    message: _emberData.default.attr('string'),
    category: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    parentType: _emberData.default.attr('string'),
    params: _emberData.default.attr(),
    markRelatedAsRead: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    createdFor: _emberData.default.belongsTo('resource'),
    createdBy: _emberData.default.belongsTo('resource'),
    dependsOn: _emberData.default.belongsTo('base'),
    creationDateFromNow: Ember.computed('creationDate', function () {
      return (0, _moment.default)(this.get('creationDate'), 'YYYY-MM-DD hh:mm:ss').fromNow();
    }),
    isUnread: Ember.computed('state', function () {
      return this.get('state') === 'new';
    }),
    isThreadRelated: Ember.computed('category', function () {
      return this.get('category') === 'thread';
    }),
    isCelebrationsRelated: Ember.computed('parentType', function () {
      return this.get('parentType') === 'app:celebrations';
    }),
    isDigitalWorkplaceDocumentRelated: Ember.computed('parentType', function () {
      return this.get('parentType') === 'app:digitalworkplacecategorydocument';
    }),
    isDigitalWorkplaceNewsRelated: Ember.computed('parentType', function () {
      return this.get('parentType') === 'app:digitalworkplacenews';
    }),
    isAlertRelated: Ember.computed('parentType', function () {
      return this.get('parentType') === 'alert';
    }),
    computedTitle: Ember.computed('title', 'category', 'type', function () {
      var title = this.get('title');
      if (this.get('category') === NOTIFICATION_CATEGORY_ACTIVITY) {
        switch (this.get('type')) {
          case 'pending':
          case 'bankingpending':
            title = "".concat(title, " <i class=\"bmi-hourglass-half\" aria-hidden=\"true\" focusable=\"false\" />");
            break;
          case 'rejected':
          case 'refused':
          case 'bankingerror':
            title = "".concat(title, " <i class=\"bmi-x-mark bmi-error-color\" aria-hidden=\"true\" focusable=\"false\" />");
            break;
          case 'bankingsuccess':
          case 'validated':
            title = "".concat(title, " <i class=\"bmi-check bmi-success-color\" aria-hidden=\"true\" focusable=\"false\" />");
            break;
          case 'bankingexpired':
            title = "".concat(title, " <i class=\"bmi-warning-alert\" aria-hidden=\"true\" focusable=\"false\" />");
            break;
        }
      }
      return title;
    }).readOnly(),
    /**
     * Détermine la classe CSS ou l'icône à afficher selon le type de notifiation
     */
    getIcon: Ember.computed('category,parentType,type', function () {
      var categoryName = this.get('category');
      var ptype = this.get('parentType');
      var type = this.get('type');
      if (categoryName === 'activity') {
        if (type === 'positioning' && ptype !== 'delivery') {
          return 'bmi-positioning';
        }
        if (type === 'bankingreconciliation' && ptype === 'invoice') {
          return 'bmi-bankingtransaction';
        }
        if (!ptype.startsWith('app:')) {
          return 'bmi-' + ptype;
        }
        return 'bmi-warning-circle';
      } else if (categoryName === 'thread') {
        return '';
      } else if (categoryName === 'corporate') {
        if (ptype === 'app:digitalworkplacecategorydocument') {
          return 'bmi-file';
        }
        if (ptype === 'app:digitalworkplacenews') {
          return 'bmi-digitalworkplace-news';
        }
        return 'bmi-face-party';
      }
      return '';
    }),
    transitionPath: Ember.computed('parentType', 'dependsOn', function () {
      var type = this.get('type');
      if (type === 'deleted') {
        return false;
      }
      switch (this.get('parentType')) {
        case 'absencesreport':
          return 'private.absencesreports.absencesreport';
        case 'contract':
          return 'private.contracts.contract';
        case 'delivery':
          return 'private.deliveries.delivery';
        case 'positioning':
          return 'private.positionings.positioning';
        case 'contact':
          return 'private.contacts.contact.overview';
        case 'opportunity':
          return 'private.opportunities.opportunity.information';
        case 'company':
          return 'private.companies.company.overview';
        case 'project':
          return 'private.projects.project.information';
        case 'quotation':
          return 'private.apps.wdevis.quotation';
        case 'order':
          return 'private.orders.order.information';
        case 'invoice':
          return 'private.invoices.invoice.information';
        case 'purchase':
          return 'private.purchases.purchase.information';
        case 'payment':
          return 'private.payments.payment';
        case 'inactivity':
          return 'private.inactivities.inactivity';
        case 'groupment':
          return 'private.groupments.groupment';
        case 'advantage':
          return 'private.advantages.advantage';
        case 'followeddocument':
          return 'private.followeddocuments.followeddocument';
        case 'action':
          return 'private.actions.action';
        case 'timesreport':
          if (type === 'autotimesheet' && !this.get('dependsOn')) {
            return 'private.resources.resource.timesreports';
          } else {
            return 'private.timesreports.timesreport';
          }
        case 'expensesreport':
          if (type === 'autoexpense' && !this.get('dependsOn')) {
            return 'private.resources.resource.expensesreports';
          } else {
            return 'private.expensesreports.expensesreport';
          }
        case 'candidate':
          {
            var path = 'private.candidates.candidate.';
            if (type === 'positioning') {
              path += 'positionings';
            } else {
              path += 'overview';
            }
            return path;
          }
        case 'resource':
          {
            var _path = 'private.resources.resource.';
            if (type === 'positioning') {
              _path += 'positionings';
            } else {
              _path += 'index';
            }
            return _path;
          }
        case 'product':
          {
            var _path2 = 'private.products.product.';
            if (type === 'positioning') {
              _path2 += 'opportunities';
            } else {
              _path2 += 'information';
            }
            return _path2;
          }
        case 'alert':
          return 'private.dashboard';
        case 'appentity':
          {
            return 'private.apps.appentities.appentity';
          }
        case 'app:celebrations':
          {
            return 'private.apps.celebrations';
          }
        case 'app:digitalworkplacecategorydocument':
          {
            return 'private.apps.digitalworkplace';
          }
        case 'app:digitalworkplacenews':
          {
            return 'private.apps.digitalworkplace';
          }
        case 'providerinvoice':
          {
            return 'private.providerinvoices.providerinvoice';
          }
        case 'bankingconnection':
          {
            return 'private.administrator.bankingconnections.bankingconnection.accounts';
          }
      }
    })
  });
  var _default = _exports.default = Notification;
});