define("boondmanager/models/contact", ["exports", "boondmanager/models/base", "boondmanager/config/environment", "ember-data", "ember-data-copyable", "ember-data-model-fragments/attributes"], function (_exports, _base, _environment, _emberData, _emberDataCopyable, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATE_CONTACT_DEFAULT = void 0;
  var STATE_CONTACT_DEFAULT = _exports.STATE_CONTACT_DEFAULT = 0;
  var Contact = _base.default.extend(_emberDataCopyable.default, {
    copyableOptions: Object.freeze({
      copyByReference: ['agency', 'company', 'mainManager', 'pole']
    }),
    // ATTRIBUTES
    //TODO when an attribute is an ID, add 'ID' after its name. so we'll can add a CP to give the real value like 'titleID' -> real 'title'
    activityAreas: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    address: _emberData.default.attr('string'),
    // TAB_CRMCONTACT.CCON_ADR
    civility: _emberData.default.attr('number'),
    // TAB_CRMCONTACT.CCON_CIVILITE
    country: _emberData.default.attr('string'),
    // TAB_CRMCONTACT.CCON_PAYS
    subDivision: _emberData.default.attr('string'),
    // TAB_CRMCONTACT.CCON_SUBDIVISION
    department: _emberData.default.attr('string'),
    // TAB_CRMCONTACT.CCON_SERVICE
    email1: _emberData.default.attr('string'),
    // TAB_CRMCONTACT.CCON_EMAIL
    email2: _emberData.default.attr('string'),
    // TAB_CRMCONTACT.CCON_EMAIL2
    email3: _emberData.default.attr('string'),
    // TAB_CRMCONTACT.CCON_EMAIL3
    fax: _emberData.default.attr('string'),
    // TAB_CRMCONTACT.CCON_FAX
    firstName: _emberData.default.attr('string'),
    // TAB_CRMCONTACT.CCON_PRENOM
    function: _emberData.default.attr('string'),
    // TAB_CRMCONTACT.CCON_FONCTION
    informationComments: _emberData.default.attr('string'),
    // TAB_CRMCONTACT.CCON_COMMENTAIRE
    lastName: _emberData.default.attr('string'),
    // TAB_CRMCONTACT.CCON_NOM
    origin: (0, _attributes.fragment)('source', {
      defaultValue: function defaultValue() {
        return {
          detail: '',
          typeOf: -1
        };
      }
    }),
    phone1: _emberData.default.attr('string'),
    // TAB_CRMCONTACT.CCON_TEL1
    phone2: _emberData.default.attr('string'),
    // TAB_CRMCONTACT.CCON_TEL2
    postcode: _emberData.default.attr('string'),
    // TAB_CRMCONTACT.CCON_CP
    state: _emberData.default.attr('number'),
    // TAB_CRMCONTACT.CCON_TYPE
    tools: _emberData.default.attr('', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    town: _emberData.default.attr('string'),
    // TAB_CRMCONTACT.CCON_VILLE
    updateDate: _emberData.default.attr('moment'),
    // TAB_CRMCONTACT.CCON_DATEUPDATE
    creationDate: _emberData.default.attr('moment'),
    // TAB_CRMCONTACT.CCON_DATEUPDATE
    thumbnail: _emberData.default.attr('string'),
    // TAB_CRMCONTACT.CCON_THUMBNAIL
    typesOf: _emberData.default.attr('array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // TAB_CRMCONTACT.CCON_TYPES

    // Contact Actions (previous & next)
    previousAction: _emberData.default.belongsTo('action', {
      inverse: null
    }),
    nextAction: _emberData.default.belongsTo('action', {
      inverse: null
    }),
    thumbnailUrl: Ember.computed('thumbnail', function () {
      if (this.get('thumbnail')) {
        return _environment.default.apiURL + '/api/thumbnails/' + this.get('thumbnail');
      } else {
        return '';
      }
    }),
    //right
    canReadContact: _emberData.default.attr('boolean'),
    canWriteContact: _emberData.default.attr('boolean'),
    // Droit d'écriture ( par procuration de suppresion)
    canShowAction: _emberData.default.attr('boolean'),
    // Droit de lecture des actions

    // Fragments
    socialNetworks: (0, _attributes.fragmentArray)('socialnetwork', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    // Relationships
    agency: _emberData.default.belongsTo(),
    company: _emberData.default.belongsTo(),
    influencers: _emberData.default.hasMany('resource', {
      inverse: 'influencerToContact'
    }),
    lastAction: _emberData.default.belongsTo('action', {
      inverse: null
    }),
    mainManager: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    pole: _emberData.default.belongsTo(),
    // Computed properties
    entityName: Ember.computed('lastName', 'firstName', function () {
      var lastName = this.get('lastName') ? this.get('lastName').toUpperCase() : '';
      var firstName = this.get('firstName');
      if (Ember.isBlank(lastName) && Ember.isBlank(firstName)) {
        return this.get('internalReference');
      } else {
        return "".concat(lastName, " ").concat(firstName);
      }
    }),
    completeName: Ember.computed('lastName', 'firstName', function () {
      var lastName = this.get('lastName') ? this.get('lastName').toUpperCase() : '';
      var firstName = this.get('firstName') || '';
      if (Ember.isBlank(lastName) && Ember.isBlank(firstName)) {
        return this.get('internalReference');
      } else {
        return "".concat(firstName, " ").concat(lastName);
      }
    }),
    emailsOptions: Ember.computed('email1', 'email2', 'email3', function () {
      var options = [];
      var emails = [];
      var distinct = function distinct(value, index, self) {
        return self.indexOf(value) === index && value;
      };
      emails.push(this.get('email1'));
      emails.push(this.get('email2'));
      emails.push(this.get('email3'));
      var distinctEmails = emails.filter(distinct);
      distinctEmails.forEach(function (email) {
        options.push({
          id: email,
          value: email
        });
      });
      return options;
    }),
    phoneOptionsForESignature: Ember.computed('id', 'modelName', 'phone1', 'phone2', function () {
      var phones = [];
      if (this.get('phone1')) {
        var phone1 = this.get('phone1').replaceAll(' ', '_');
        phones.push({
          id: "".concat(this.get('modelName'), "_").concat(this.get('id'), "_").concat(phone1),
          value: this.get('phone1')
        });
      }
      if (this.get('phone2')) {
        var phone2 = this.get('phone2').replaceAll(' ', '_');
        phones.push({
          id: "".concat(this.get('modelName'), "_").concat(this.get('id'), "_").concat(phone2),
          value: this.get('phone2')
        });
      }
      return phones;
    }).readOnly(),
    emailOptionsForESignature: Ember.computed('id', 'completeName', 'modelName', 'email1', 'email2', 'email3', function () {
      var emails = [];
      if (this.get('email1')) {
        emails.push({
          id: "".concat(this.get('modelName'), "_").concat(this.get('id'), "_").concat(this.get('email1')),
          email: this.get('email1'),
          value: "".concat(this.get('completeName'), " - ").concat(this.get('email1')),
          entityId: "".concat(this.get('modelName'), "_").concat(this.get('id')),
          phoneOptionsForESignature: this.get('phoneOptionsForESignature')
        });
      }
      if (this.get('email2')) {
        emails.push({
          id: "".concat(this.get('modelName'), "_").concat(this.get('id'), "_").concat(this.get('email2')),
          email: this.get('email2'),
          value: "".concat(this.get('completeName'), " - ").concat(this.get('email2')),
          entityId: "".concat(this.get('modelName'), "_").concat(this.get('id')),
          phoneOptionsForESignature: this.get('phoneOptionsForESignature')
        });
      }
      if (this.get('email3')) {
        emails.push({
          id: "".concat(this.get('modelName'), "_").concat(this.get('id'), "_").concat(this.get('email3')),
          email: this.get('email3'),
          value: "".concat(this.get('completeName'), " - ").concat(this.get('email3')),
          entityId: "".concat(this.get('modelName'), "_").concat(this.get('id')),
          phoneOptionsForESignature: this.get('phoneOptionsForESignature')
        });
      }
      return emails;
    }).readOnly()
  });
  Contact.reopenClass({
    prefix: 'CCON'
  });
  Contact.reopen({
    prefix: Ember.computed(function () {
      return Contact.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Contact;
});