define("boondmanager/models/log", ["exports", "boondmanager/models/base", "ember-data"], function (_exports, _base, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TYPEOF_WEBHOOK = _exports.TYPEOF_VALIDATION = _exports.TYPEOF_TODOLIST = _exports.TYPEOF_TIMESREPORT = _exports.TYPEOF_TASK = _exports.TYPEOF_TARGET = _exports.TYPEOF_SAVEDSEARCH = _exports.TYPEOF_ROLETEMPLATE = _exports.TYPEOF_ROLE = _exports.TYPEOF_RESOURCE = _exports.TYPEOF_QUOTATION = _exports.TYPEOF_PURCHASE = _exports.TYPEOF_PROVIDER_INVOICE = _exports.TYPEOF_PROJECT = _exports.TYPEOF_PRODUCT = _exports.TYPEOF_POSITIONING = _exports.TYPEOF_POLE = _exports.TYPEOF_PAYMENT = _exports.TYPEOF_ORDER = _exports.TYPEOF_OPPORTUNITY = _exports.TYPEOF_INVOICE = _exports.TYPEOF_INACTIVITY = _exports.TYPEOF_GROUPMENT = _exports.TYPEOF_GLOBAL = _exports.TYPEOF_FORMTEMPLATE = _exports.TYPEOF_FORM = _exports.TYPEOF_FOLLOWEDDOCUMENT = _exports.TYPEOF_FLAG = _exports.TYPEOF_EXPENSESREPORT = _exports.TYPEOF_EMAILING_TEMPLATE = _exports.TYPEOF_DIGITAL_WORKPLACE_NEWS = _exports.TYPEOF_DIGITAL_WORKPLACE_CATEGORY = _exports.TYPEOF_DEVICE = _exports.TYPEOF_DELIVERY = _exports.TYPEOF_DASHBOARD = _exports.TYPEOF_CUSTOMER = _exports.TYPEOF_CONTRACT = _exports.TYPEOF_CONTACT = _exports.TYPEOF_COMPANY = _exports.TYPEOF_CANDIDATE = _exports.TYPEOF_BUSINESSUNIT = _exports.TYPEOF_BANKING_TRANSACTION = _exports.TYPEOF_BANKING_CONNECTION = _exports.TYPEOF_BANKING_ACCOUNT = _exports.TYPEOF_APPENTITY = _exports.TYPEOF_APP = _exports.TYPEOF_AGENCY = _exports.TYPEOF_ADVANTAGE = _exports.TYPEOF_ACTIONTEMPLATE = _exports.TYPEOF_ACTION = _exports.TYPEOF_ABSENCESRREPORT = _exports.TITLES = _exports.ALL_TYPES = void 0;
  var TYPEOF_GLOBAL = _exports.TYPEOF_GLOBAL = 'global';
  var TYPEOF_RESOURCE = _exports.TYPEOF_RESOURCE = 'resource';
  var TYPEOF_CANDIDATE = _exports.TYPEOF_CANDIDATE = 'candidate';
  var TYPEOF_ADVANTAGE = _exports.TYPEOF_ADVANTAGE = 'advantage';
  var TYPEOF_FOLLOWEDDOCUMENT = _exports.TYPEOF_FOLLOWEDDOCUMENT = 'followeddocument';
  var TYPEOF_CONTRACT = _exports.TYPEOF_CONTRACT = 'contract';
  var TYPEOF_PRODUCT = _exports.TYPEOF_PRODUCT = 'product';
  var TYPEOF_PURCHASE = _exports.TYPEOF_PURCHASE = 'purchase';
  var TYPEOF_OPPORTUNITY = _exports.TYPEOF_OPPORTUNITY = 'opportunity';
  var TYPEOF_POSITIONING = _exports.TYPEOF_POSITIONING = 'positioning';
  var TYPEOF_PAYMENT = _exports.TYPEOF_PAYMENT = 'payment';
  var TYPEOF_PROJECT = _exports.TYPEOF_PROJECT = 'project';
  var TYPEOF_DELIVERY = _exports.TYPEOF_DELIVERY = 'delivery';
  var TYPEOF_INACTIVITY = _exports.TYPEOF_INACTIVITY = 'inactivity';
  var TYPEOF_GROUPMENT = _exports.TYPEOF_GROUPMENT = 'groupment';
  var TYPEOF_CONTACT = _exports.TYPEOF_CONTACT = 'contact';
  var TYPEOF_COMPANY = _exports.TYPEOF_COMPANY = 'company';
  var TYPEOF_BUSINESSUNIT = _exports.TYPEOF_BUSINESSUNIT = 'businessunit';
  var TYPEOF_ROLE = _exports.TYPEOF_ROLE = 'role';
  var TYPEOF_POLE = _exports.TYPEOF_POLE = 'pole';
  var TYPEOF_AGENCY = _exports.TYPEOF_AGENCY = 'agency';
  var TYPEOF_TIMESREPORT = _exports.TYPEOF_TIMESREPORT = 'timesreport';
  var TYPEOF_EXPENSESREPORT = _exports.TYPEOF_EXPENSESREPORT = 'expensesreport';
  var TYPEOF_ABSENCESRREPORT = _exports.TYPEOF_ABSENCESRREPORT = 'absencesreport';
  var TYPEOF_ACTION = _exports.TYPEOF_ACTION = 'action';
  var TYPEOF_ACTIONTEMPLATE = _exports.TYPEOF_ACTIONTEMPLATE = 'actiontemplate';
  var TYPEOF_EMAILING_TEMPLATE = _exports.TYPEOF_EMAILING_TEMPLATE = 'appemailingtemplate';
  var TYPEOF_ROLETEMPLATE = _exports.TYPEOF_ROLETEMPLATE = 'roletemplate';
  var TYPEOF_ORDER = _exports.TYPEOF_ORDER = 'order';
  var TYPEOF_INVOICE = _exports.TYPEOF_INVOICE = 'invoice';
  var TYPEOF_QUOTATION = _exports.TYPEOF_QUOTATION = 'quotation';
  var TYPEOF_DEVICE = _exports.TYPEOF_DEVICE = 'device';
  var TYPEOF_CUSTOMER = _exports.TYPEOF_CUSTOMER = 'customer';
  var TYPEOF_SAVEDSEARCH = _exports.TYPEOF_SAVEDSEARCH = 'savedsearch';
  var TYPEOF_VALIDATION = _exports.TYPEOF_VALIDATION = 'validation';
  var TYPEOF_APP = _exports.TYPEOF_APP = 'app';
  var TYPEOF_FLAG = _exports.TYPEOF_FLAG = 'flag';
  var TYPEOF_TARGET = _exports.TYPEOF_TARGET = 'target';
  var TYPEOF_TODOLIST = _exports.TYPEOF_TODOLIST = 'todolist';
  var TYPEOF_TASK = _exports.TYPEOF_TASK = 'task';
  var TYPEOF_FORMTEMPLATE = _exports.TYPEOF_FORMTEMPLATE = 'formtemplate';
  var TYPEOF_FORM = _exports.TYPEOF_FORM = 'form';
  var TYPEOF_DASHBOARD = _exports.TYPEOF_DASHBOARD = 'dashboard';
  var TYPEOF_DIGITAL_WORKPLACE_CATEGORY = _exports.TYPEOF_DIGITAL_WORKPLACE_CATEGORY = 'appdigitalworkplacecategory';
  var TYPEOF_DIGITAL_WORKPLACE_NEWS = _exports.TYPEOF_DIGITAL_WORKPLACE_NEWS = 'appdigitalworkplacenews';
  var TYPEOF_APPENTITY = _exports.TYPEOF_APPENTITY = 'appentity';
  var TYPEOF_WEBHOOK = _exports.TYPEOF_WEBHOOK = 'webhook';
  var TYPEOF_PROVIDER_INVOICE = _exports.TYPEOF_PROVIDER_INVOICE = 'providerinvoice';
  var TYPEOF_BANKING_TRANSACTION = _exports.TYPEOF_BANKING_TRANSACTION = 'bankingtransaction';
  var TYPEOF_BANKING_CONNECTION = _exports.TYPEOF_BANKING_CONNECTION = 'bankingconnection';
  var TYPEOF_BANKING_ACCOUNT = _exports.TYPEOF_BANKING_ACCOUNT = 'bankingaccount';
  var ALL_TYPES = _exports.ALL_TYPES = [TYPEOF_GLOBAL, TYPEOF_RESOURCE, TYPEOF_CANDIDATE, TYPEOF_ADVANTAGE, TYPEOF_FOLLOWEDDOCUMENT, TYPEOF_CONTRACT, TYPEOF_PRODUCT, TYPEOF_PURCHASE, TYPEOF_PAYMENT, TYPEOF_PROJECT, TYPEOF_DELIVERY, TYPEOF_INACTIVITY, TYPEOF_GROUPMENT, TYPEOF_CONTACT, TYPEOF_COMPANY, TYPEOF_BUSINESSUNIT, TYPEOF_ROLE, TYPEOF_POLE, TYPEOF_AGENCY, TYPEOF_TIMESREPORT, TYPEOF_EXPENSESREPORT, TYPEOF_ABSENCESRREPORT, TYPEOF_ACTION, TYPEOF_ACTIONTEMPLATE, TYPEOF_EMAILING_TEMPLATE, TYPEOF_ORDER, TYPEOF_INVOICE, TYPEOF_QUOTATION, TYPEOF_OPPORTUNITY, TYPEOF_POSITIONING, TYPEOF_DEVICE, TYPEOF_CUSTOMER, TYPEOF_SAVEDSEARCH, TYPEOF_VALIDATION, TYPEOF_APP, TYPEOF_FLAG, TYPEOF_TARGET, TYPEOF_TODOLIST, TYPEOF_TASK, TYPEOF_ROLETEMPLATE, TYPEOF_FORMTEMPLATE, TYPEOF_FORM, TYPEOF_DASHBOARD, TYPEOF_DIGITAL_WORKPLACE_CATEGORY, TYPEOF_DIGITAL_WORKPLACE_NEWS, TYPEOF_APPENTITY, TYPEOF_WEBHOOK, TYPEOF_PROVIDER_INVOICE, TYPEOF_BANKING_TRANSACTION, TYPEOF_BANKING_CONNECTION, TYPEOF_BANKING_ACCOUNT];
  var TITLES = _exports.TITLES = {};
  TITLES[TYPEOF_GLOBAL] = 'logs:search.global';
  TITLES[TYPEOF_RESOURCE] = 'models:employee.name';
  TITLES[TYPEOF_CANDIDATE] = 'models:candidate.name';
  TITLES[TYPEOF_ADVANTAGE] = 'models:advantage.name';
  TITLES[TYPEOF_FOLLOWEDDOCUMENT] = 'models:followeddocument.name';
  TITLES[TYPEOF_CONTRACT] = 'models:contract.name';
  TITLES[TYPEOF_PRODUCT] = 'models:product.name';
  TITLES[TYPEOF_PURCHASE] = 'models:purchase.name';
  TITLES[TYPEOF_PAYMENT] = 'models:payment.name';
  TITLES[TYPEOF_PROJECT] = 'models:project.name';
  TITLES[TYPEOF_DELIVERY] = 'models:delivery.name';
  TITLES[TYPEOF_INACTIVITY] = 'models:inactivity.name';
  TITLES[TYPEOF_GROUPMENT] = 'models:groupment.name';
  TITLES[TYPEOF_CONTACT] = 'models:contact.name';
  TITLES[TYPEOF_COMPANY] = 'models:company.name';
  TITLES[TYPEOF_BUSINESSUNIT] = 'models:businessunit.name';
  TITLES[TYPEOF_ROLE] = 'models:role.name';
  TITLES[TYPEOF_POLE] = 'models:pole.name';
  TITLES[TYPEOF_AGENCY] = 'models:agency.name';
  TITLES[TYPEOF_TIMESREPORT] = 'models:timesreport.name';
  TITLES[TYPEOF_EXPENSESREPORT] = 'models:expensesreport.name';
  TITLES[TYPEOF_ABSENCESRREPORT] = 'models:absencesreport.name';
  TITLES[TYPEOF_ACTION] = 'models:action.name';
  TITLES[TYPEOF_ACTIONTEMPLATE] = 'models:actiontemplate.name';
  TITLES[TYPEOF_EMAILING_TEMPLATE] = 'models:appemailingtemplate.name';
  TITLES[TYPEOF_ORDER] = 'models:order.name';
  TITLES[TYPEOF_INVOICE] = 'models:invoice.name';
  TITLES[TYPEOF_QUOTATION] = 'models:quotation.name';
  TITLES[TYPEOF_OPPORTUNITY] = 'models:opportunity.name';
  TITLES[TYPEOF_POSITIONING] = 'models:positioning.name';
  TITLES[TYPEOF_DEVICE] = 'models:device.name';
  TITLES[TYPEOF_CUSTOMER] = 'models:customer.name';
  TITLES[TYPEOF_SAVEDSEARCH] = 'models:savedsearch.name';
  TITLES[TYPEOF_VALIDATION] = 'models:validation.name';
  TITLES[TYPEOF_APP] = 'models:app.name';
  TITLES[TYPEOF_FLAG] = 'models:flag.name';
  TITLES[TYPEOF_TARGET] = 'models:target.name';
  TITLES[TYPEOF_TODOLIST] = 'models:todolist.name';
  TITLES[TYPEOF_TASK] = 'models:task.name';
  TITLES[TYPEOF_ROLETEMPLATE] = 'models:roletemplate.name';
  TITLES[TYPEOF_FORMTEMPLATE] = 'models:formtemplate.name';
  TITLES[TYPEOF_FORM] = 'models:form.name';
  TITLES[TYPEOF_DASHBOARD] = 'models:dashboard.name';
  TITLES[TYPEOF_DIGITAL_WORKPLACE_CATEGORY] = 'models:appdigitalworkplacecategory.logName';
  TITLES[TYPEOF_DIGITAL_WORKPLACE_NEWS] = 'models:appdigitalworkplacenews.logName';
  TITLES[TYPEOF_APPENTITY] = 'models:appentity.name';
  TITLES[TYPEOF_WEBHOOK] = 'models:webhook.name';
  TITLES[TYPEOF_PROVIDER_INVOICE] = 'models:providerinvoice.name';
  TITLES[TYPEOF_BANKING_TRANSACTION] = 'models:bankingtransaction.name';
  TITLES[TYPEOF_BANKING_CONNECTION] = 'models:bankingconnection.name';
  TITLES[TYPEOF_BANKING_ACCOUNT] = 'models:bankingaccount.name';
  var Log = _base.default.extend({
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      ALL_TYPES.forEach(function (cat) {
        var catName = Ember.String.capitalize(cat);
        Ember.defineProperty(_this, "is".concat(catName, "Log"), Ember.computed.equal('typeOf', cat));
      });
    },
    creationDate: _emberData.default.attr('moment'),
    typeOf: _emberData.default.attr('string', {
      defaultValue: 'global'
    }),
    auth: _emberData.default.attr('string', {
      defaultValue: 'normal'
    }),
    action: _emberData.default.attr('string', {
      defaultValue: 'info'
    }),
    content: _emberData.default.attr(''),
    text: _emberData.default.attr('string'),
    numberOfFiles: _emberData.default.attr('number'),
    canReadLog: _emberData.default.attr('boolean'),
    canReadDependsOn: _emberData.default.attr('boolean'),
    isEntityDeleted: _emberData.default.attr('boolean'),
    dependsOn: _emberData.default.belongsTo('base'),
    company: _emberData.default.belongsTo(),
    files: _emberData.default.hasMany('document'),
    file: _emberData.default.belongsTo(),
    createdBy: _emberData.default.belongsTo('resource', {
      inverse: null
    }),
    createdFromApp: _emberData.default.belongsTo('app', {
      inverse: null
    }),
    authValue: Ember.computed('auth', 'i18n._locale', function () {
      var id = this.get('auth');
      var option = this.get('settingsService').bm.logs.auth.option.find(function (item) {
        return item.id === id;
      });
      return typeof option !== 'undefined' ? option.value : '';
    }),
    typeOfValue: Ember.computed('typeOf', 'i18n._locale', function () {
      var id = this.get('typeOf');
      return this.get('i18n').t(TITLES[id]);
    }),
    actionValue: Ember.computed('action', 'i18n._locale', function () {
      var id = this.get('action');
      var option = this.get('settingsService').bm.logs.action.option.find(function (item) {
        return item.id === id;
      });
      return typeof option !== 'undefined' ? option.value : '';
    }),
    dependsOnOpenModalNative: Ember.computed('typeOf', function () {
      return ['action'].includes(this.get('typeOf'));
    }),
    dependsOnPath: Ember.computed('typeOf', 'dependsOn.dependsOn.modelName', function () {
      return this._getPathTypeOf(this.get('typeOf'));
    }),
    dependsOnId: Ember.computed('typeOf', 'dependsOn.{id,dependsOn.id}', function () {
      return this.get('typeOf') === 'task' ? this.get('dependsOn.dependsOn.id') : this.get('dependsOn.id');
    }),
    dependsOnPathRequireId: Ember.computed('typeOf', 'dependsOn.dependsOn.modelName', function () {
      var typeOf = this.get('typeOf');
      switch (typeOf) {
        case 'appdigitalworkplacecategory':
        case 'appdigitalworkplacenews':
        case 'bankingtransaction':
        case 'bankingconnection':
          return false;
      }
      return true;
    }),
    dependsOnAppId: Ember.computed('typeOf', 'content.context.app.id', function () {
      return this.get('typeOf') === 'appentity' ? this.get('content.context.app.id') : 0;
    }),
    dependsOnName: Ember.computed('typeOf', 'dependsOn.{entityName,internalReference}', 'dependsOn.dependsOn.{entityName,internalReference}', function () {
      var dependsOn = this.get('dependsOn');
      return dependsOn.get('entityName') ? dependsOn.get('entityName') : dependsOn.get('internalReference');
    }),
    /**
     * Retourne le path pour le lien vers le dependsOn du log
     * @param typeOf
     * @returns {string|boolean}
     * @private
     */
    _getPathTypeOf: function _getPathTypeOf(typeOf) {
      switch (typeOf) {
        case 'candidate':
          return 'private.candidates.candidate.overview';
        case 'resource':
          return 'private.resources.resource.overview';
        case 'contact':
          return 'private.contacts.contact.overview';
        case 'company':
          return 'private.companies.company.overview';
        case 'product':
          return 'private.products.product.information';
        case 'purchase':
          return 'private.purchases.purchase.information';
        case 'project':
          return 'private.projects.project.information';
        case 'opportunity':
          return 'private.opportunities.opportunity.information';
        case 'invoice':
          return 'private.invoices.invoice.information';
        case 'order':
          return 'private.orders.order.information';
        case 'app':
          return 'private.administrator.appsdeveloped.app.information';
        case 'payment':
          return 'private.payments.payment';
        case 'positioning':
          return 'private.positionings.positioning';
        case 'contract':
          return 'private.contracts.contract';
        case 'advantage':
          return 'private.advantages.advantage';
        case 'followeddocument':
          return 'private.followeddocuments.followeddocument';
        case 'quotation':
          return 'private.apps.wdevis.quotation';
        case 'delivery':
          return 'private.deliveries.delivery';
        case 'inactivity':
          return 'private.inactivities.inactivity';
        case 'groupment':
          return 'private.groupments.groupment';
        case 'timesreport':
          return 'private.timesreports.timesreport';
        case 'expensesreport':
          return 'private.expensesreports.expensesreport';
        case 'absencesreport':
          return 'private.absencesreports.absencesreport';
        case 'businessunit':
          return 'private.administrator.businessunits.businessunit';
        case 'pole':
          return 'private.administrator.poles.pole';
        case 'role':
          return 'private.administrator.roles.role';
        case 'agency':
          return 'private.administrator.agencies.agency';
        case 'customer':
          return 'private.administrator.customers.customer';
        case 'todolist':
          return 'private.administrator.todolists.todolist';
        case 'formtemplate':
          return 'private.administrator.forms.form';
        case 'form':
          return 'private.forms.form';
        case 'appdigitalworkplacecategory':
        case 'appdigitalworkplacenews':
          return 'private.apps.digitalworkplace.index';
        case 'appentity':
          return 'private.apps.appentities.appentity';
        case 'providerinvoice':
          return 'private.providerinvoices.providerinvoice';
        case 'bankingtransaction':
          return 'private.bankingtransactions';
        case 'bankingconnection':
          return 'private.administrator.bankingconnections';
      }
      return false;
    }
  });
  Log.reopenClass({
    prefix: 'LOG'
  });
  Log.reopen({
    prefix: Ember.computed(function () {
      return Log.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Log;
});