define("boondmanager/models/bankingconnection", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable"], function (_exports, _base, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var STATE_ACTIVE = 'active';
  var STATE_SCAREQUIRED = 'SCARequired';
  var STATE_ADDITIONAL_INFO = 'additionalInformationNeeded';
  var STATE_DECOUPLED = 'decoupled';
  var STATE_WEBAUTHREQUIRED = 'webauthRequired';
  var STATE_WRONGPASS = 'wrongpass';
  var BankingConnection = _base.default.extend(_emberDataCopyable.default, {
    providerId: _emberData.default.attr('string'),
    lastUpdate: _emberData.default.attr('moment'),
    nextUpdate: _emberData.default.attr('moment'),
    expire: _emberData.default.attr('moment'),
    active: _emberData.default.attr('boolean'),
    deleted: _emberData.default.attr('boolean'),
    state: _emberData.default.attr('string'),
    bankName: _emberData.default.attr('string'),
    numberOfAccount: _emberData.default.attr('number'),
    computedState: Ember.computed('active', 'state', function () {
      if (this.get('active') && !this.get('state').length) {
        return STATE_ACTIVE;
      }
      return this.get('state');
    }).readOnly(),
    stateClass: Ember.computed('state', 'i18n._locale', function () {
      var _this = this;
      var states = this.i18n.t('models:bankingconnection.attributes.state.values', {
        returnObjects: true
      });
      var state = states.find(function (s) {
        return s.id === _this.get('computedState');
      });
      return "bm-state-color-".concat(state ? state.color : '');
    }).readOnly(),
    hasError: Ember.computed('computedState', function () {
      return this.get('computedState') !== 'active';
    }).readOnly(),
    needRefresh: Ember.computed('computedState', function () {
      return [STATE_SCAREQUIRED, STATE_ADDITIONAL_INFO, STATE_DECOUPLED, STATE_WEBAUTHREQUIRED, STATE_WRONGPASS].indexOf(this.get('computedState')) > -1;
    }).readOnly()
  });
  BankingConnection.reopenClass({
    prefix: 'BNKCNT'
  });
  BankingConnection.reopen({
    prefix: Ember.computed(function () {
      return BankingConnection.prefix;
    }).readOnly()
  });
  var _default = _exports.default = BankingConnection;
});