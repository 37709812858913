define("boondmanager/models/bankingwebview", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable"], function (_exports, _base, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var BankingWebview = _base.default.extend(_emberDataCopyable.default, {
    domain: _emberData.default.attr('string'),
    clientId: _emberData.default.attr('string'),
    customerCode: _emberData.default.attr('string'),
    redirectUrls: _emberData.default.attr('array')
  });
  BankingWebview.reopenClass({
    prefix: 'BNKWEB'
  });
  BankingWebview.reopen({
    prefix: Ember.computed(function () {
      return BankingWebview.prefix;
    }).readOnly()
  });
  var _default = _exports.default = BankingWebview;
});