define("boondmanager/models/resource", ["exports", "ember-data", "boondmanager/models/profile", "ember-data-model-fragments/attributes", "moment", "boondmanager/utils/activity", "boondmanager/models/baseactivity", "boondmanager/utils/bm"], function (_exports, _emberData, _profile, _attributes, _moment, _activity, _baseactivity, _bm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.viewer_appcode = _exports.default = _exports.SYSTEM_USER_THEME = _exports.STATE_OUT = _exports.STATE_ON_GOING = _exports.POSTPRODUCTION_APPCODE = _exports.LIGHT_USER_THEME = _exports.LEVELS = _exports.DARK_USER_THEME = _exports.ACTIVITY_TYPE_DEFAULT = void 0;
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  var LEVELS = _exports.LEVELS = ['manager', 'resource', 'administrator'];
  var POSTPRODUCTION_APPCODE = _exports.POSTPRODUCTION_APPCODE = 'wpostproduction';
  var viewer_appcode = _exports.viewer_appcode = 'gviewer';
  var STATE_OUT = _exports.STATE_OUT = 0;
  var STATE_ON_GOING = _exports.STATE_ON_GOING = 1;
  var SYSTEM_USER_THEME = _exports.SYSTEM_USER_THEME = 'system';
  var DARK_USER_THEME = _exports.DARK_USER_THEME = 'dark';
  var LIGHT_USER_THEME = _exports.LIGHT_USER_THEME = 'light';
  var ACTIVITY_TYPE_DEFAULT = _exports.ACTIVITY_TYPE_DEFAULT = -1;
  var Resource = _profile.default.extend({
    copyableOptions: Object.freeze({
      copyByReference: ['agency', 'apps', 'hrManager', 'mainManager', 'pole', 'providerCompany', 'providerContact', 'candidate', 'otherPoles', 'otherAgencies', 'stateReason']
    }),
    systemTheme: Ember.inject.service('system-theme'),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);

      /**
       * On Init, create some boolean CPs based on LEVELS
       *   * isManager
       *   * isResource
       *   * isAdministrator
       *   * ...
       */
      LEVELS.forEach(function (level) {
        var levelName = Ember.String.capitalize(level);
        Ember.defineProperty(_this, "is".concat(levelName), Ember.computed.equal('level', level));
      });
    },
    /*** ATTRIBUTES ****************************************/
    login: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    subscription: _emberData.default.attr('string'),
    level: _emberData.default.attr('string'),
    language: _emberData.default.attr('string'),
    homepage: _emberData.default.attr('string'),
    theme: _emberData.default.attr('string'),
    timezone: _emberData.default.attr('string'),
    narrowPerimeter: _emberData.default.attr('boolean'),
    defaultSearchSaving: _emberData.default.attr('boolean'),
    absencesReportsWorkflow: _emberData.default.attr(),
    activityExpensesStartDate: _emberData.default.attr('string'),
    allowExceptionalTimes: _emberData.default.attr('number'),
    canRecoverExceptionalTimes: _emberData.default.attr('boolean'),
    allowAbsences: _emberData.default.attr('number'),
    allowInternalActivity: _emberData.default.attr('number'),
    allowProduction: _emberData.default.attr('number'),
    experience: _emberData.default.attr('number'),
    averageDailyPriceExcludingTax: _emberData.default.attr('number'),
    // TAB_PROFIL.PARAM_TARIF1
    dashboards: _emberData.default.hasMany('dashboard'),
    documentsAutoCreation: _emberData.default.attr('string'),
    expensesReportsWorkflow: _emberData.default.attr(),
    forceAvailability: _emberData.default.attr('boolean'),
    function: _emberData.default.attr('string'),
    // TAB_PROFIL.PROFIL_STATUT
    apiAccess: _emberData.default.attr('boolean'),
    logInOnlyFromThirdParty: _emberData.default.attr('boolean'),
    manageAllowedDevices: _emberData.default.attr('boolean'),
    reference: _emberData.default.attr('string'),
    // TAB_PROFIL.PROFIL_REFERENCE
    resourceCanModifyTechnicalData: _emberData.default.attr('boolean'),
    timesReportsWorkflow: _emberData.default.attr(),
    userToken: _emberData.default.attr('string'),
    warningForNewDevice: _emberData.default.attr('boolean'),
    canReadResource: _emberData.default.attr('boolean'),
    canWriteResource: _emberData.default.attr('boolean'),
    canReadAbsences: _emberData.default.attr('boolean'),
    canShowActions: _emberData.default.attr('boolean'),
    // Droit d'accès aux données action
    canShowTechnicalData: _emberData.default.attr('boolean'),
    // Droits d'accès aux données dossier technique
    isLocked: _emberData.default.attr('boolean'),
    seniorityDate: _emberData.default.attr('moment'),
    originalSeniorityDate: _emberData.default.attr('date'),
    validitySeniorityDate: _emberData.default.attr('date'),
    forceSeniorityDate: _emberData.default.attr('boolean'),
    dynamicSeniorityYears: _emberData.default.attr('number'),
    celebrationBirthdayDate: _emberData.default.attr('moment'),
    celebrationSeniorityDate: _emberData.default.attr('moment'),
    resourceTypes: _emberData.default.attr(),
    notificationsSettings: (0, _attributes.fragmentArray)('notificationssettingsfragment'),
    positioningSuggest: (0, _attributes.fragment)('positioningsuggestfragment', {
      defaultValue: null
    }),
    /** Alertes **/
    dayForWeeklyAlerts: _emberData.default.attr(),
    hourForWeeklyAlerts: _emberData.default.attr(),
    daysForDailyAlerts: _emberData.default.attr(),
    hourForDailyAlerts: _emberData.default.attr(),
    alerts: (0, _attributes.fragmentArray)('alertfragment'),
    /* Pour signaler le changement du model à EmberJs et activer le bouton save
     * sans sauvegarder
     */
    refresh: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    features: _emberData.default.attr(),
    navigationBar: _emberData.default.attr(),
    advancedRights: _emberData.default.attr(),
    isOwner: _emberData.default.attr('boolean'),
    /**
     * If it's a string = "notUsed"... :/ And what else!? (o_O)
     *
     * @type {String|Number}
     */
    workUnitRate: _emberData.default.attr(),
    /**
     * Liste des WUT d'absences autorisés pour l'utilisateur (utilisé uniquement dans la configuration intranet de l'utilisateur)
     * rempli si allowAbsences = 1 (config personalisée selectionnée)
     */
    workUnitTypesAllowedForAbsences: (0, _attributes.fragmentArray)('workunittype', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    /**
     * Liste des WUT d'activité exceptionelle autorisés pour l'utilisateur (utilisé uniquement dans la configuration intranet de l'utilisateur)
     * rempli si allowExceptionalTimes = 1 (config personalisée selectionnée)
     */
    workUnitTypesAllowedForExceptionalActivity: (0, _attributes.fragmentArray)('workunittype', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    /**
     * Liste des WUT d'activité de production autorisés pour l'utilisateur (utilisé uniquement dans la configuration intranet de l'utilisateur)
     * rempli si allowProduction = 1 (config personalisée selectionnée)
     */
    workUnitTypesAllowedForProduction: (0, _attributes.fragmentArray)('workunittype', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    /**
     * Liste des WUT d'activité interne autorisés pour l'utilisateur (utilisé uniquement dans la configuration intranet de l'utilisateur)
     * rempli si allowInternalActivity = 1 (config personalisée selectionnée)
     */
    workUnitTypesAllowedForInternalActivity: (0, _attributes.fragmentArray)('workunittype', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    /**
     * Types d'UO autorisés dans les feuilles de temps et les demandes d'absences
     * Propriété calculé côté back à l'aide de la config agence des types d'UO (agence.workUnitTypes) et des propriétés ci-dessous
     * - workUnitTypesAllowedForAbsences
     * - workUnitTypesAllowedForExceptionalActivity
     * - workUnitTypesAllowedForProduction
     * - workUnitTypesAllowedForInternalActivity
     */
    workUnitTypesAllowed: (0, _attributes.fragmentArray)('workunittype', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    nodeSignedRequest: _emberData.default.attr('string'),
    nodeUrl: _emberData.default.attr('string'),
    typeOf: _emberData.default.attr('number'),
    groups: (0, _attributes.fragmentArray)('group', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    backupSeniorityDate: '',
    indicators: (0, _attributes.fragmentArray)('indicatorfragment'),
    commercialSynthesisScorecards: (0, _attributes.fragmentArray)('scorecard'),
    humanResourcesSynthesisScorecards: (0, _attributes.fragmentArray)('scorecard'),
    recruitmentSynthesisScorecards: (0, _attributes.fragmentArray)('scorecard'),
    activityExpensesSynthesisScorecards: (0, _attributes.fragmentArray)('scorecard'),
    billingSynthesisScorecards: (0, _attributes.fragmentArray)('scorecard'),
    globalSynthesisScorecards: (0, _attributes.fragmentArray)('scorecard'),
    companiesScorecards: (0, _attributes.fragmentArray)('scorecard'),
    projectsScorecards: (0, _attributes.fragmentArray)('scorecard'),
    resourcesScorecards: (0, _attributes.fragmentArray)('scorecard'),
    absencesAccounts: (0, _attributes.fragmentArray)('absencesaccountfragment', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    languagesList: Ember.computed('languages', function () {
      var languagesList = [];
      this.get('languages').forEach(function (language) {
        languagesList.push(language.language);
      });
      return languagesList;
    }),
    /**
     * List of planned times
     * @type {Regulartime[]}
     */
    plannedTimes: (0, _attributes.fragmentArray)('plannedtimeresource'),
    workplacesDefaultWeek: (0, _attributes.fragmentArray)('workplacesdefaultweek'),
    systemSettings: (0, _attributes.fragment)('systemsettingsfragment', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    /**** Relationships ****/
    candidate: _emberData.default.belongsTo('candidate', {
      inverse: null
    }),
    lastContract: _emberData.default.belongsTo('contract', {
      inverse: null
    }),
    contracts: _emberData.default.hasMany('contract', {
      inverse: null
    }),
    deliveries: _emberData.default.hasMany('delivery', {
      inverse: null
    }),
    inactivities: _emberData.default.hasMany('inactivity'),
    // inverse null to avoid on absence report profile when creating new report to add it on resource absencesReports array
    absencesReports: _emberData.default.hasMany('absencesreport', {
      inverse: null
    }),
    influencerToCompany: _emberData.default.hasMany('company', {
      inverse: 'influencers'
    }),
    influencerToContact: _emberData.default.hasMany('contact', {
      inverse: 'influencers'
    }),
    devices: _emberData.default.hasMany('device'),
    oauthThirdParties: _emberData.default.hasMany('oauththirdparty'),
    managers: _emberData.default.hasMany('resource', {
      inverse: null
    }),
    positionings: _emberData.default.hasMany('positioning', {
      inverse: null
    }),
    timesReports: _emberData.default.hasMany('timesreport', {
      inverse: 'resource'
    }),
    role: _emberData.default.belongsTo('role'),
    otherAgencies: _emberData.default.hasMany('agency', {
      inverse: null
    }),
    otherPoles: _emberData.default.hasMany('pole', {
      inverse: null
    }),
    lastAction: _emberData.default.belongsTo('action', {
      inverse: null
    }),
    /**
     * Liste des absences validées (key : YYYYMMDD - value : durée)
     * @type array
     */
    validatedAbsencesDurationsByDay: [],
    /**
     * Liste des absences non validées (key : YYYYMMDD - value : durée)
     * @type array
     */
    unvalidatedAbsencesDurationsByDay: [],
    /**
     * Liste des absences (key : YYYYMMDD - value : )
     * @type array
     */
    absencesByDay: [],
    /**
     * Durée totale des absences validées et non entièrement validées
     * @type int
     */
    totalAbsencesDurations: 0,
    parentContracts: Ember.computed('contracts', function () {
      var parentContracts = Ember.A();
      var parentContract;
      this.get('contracts').forEach(function (contract) {
        parentContract = contract.get('parentContract.id');
        if (parentContract) {
          parentContracts.pushObject(parentContract);
        }
      });
      return parentContracts;
    }),
    canShowSeniorityDate: Ember.computed('seniorityDate', function () {
      var seniorityDate = this.get('seniorityDate');
      return seniorityDate && seniorityDate <= (0, _moment.default)();
    }),
    seniority: Ember.computed('seniorityDate', 'i18n', function () {
      var i18n = this.get('i18n');
      var seniorityDate = this.get('seniorityDate');
      var validitySeniorityDate = this.get('validitySeniorityDate');
      var forceSeniorityDate = this.get('forceSeniorityDate');
      var months = null;
      var years = null;
      if (!seniorityDate || seniorityDate > (0, _moment.default)()) return '';
      var endate = validitySeniorityDate && validitySeniorityDate < (0, _moment.default)() && !forceSeniorityDate ? validitySeniorityDate.clone() : (0, _moment.default)();
      if (endate.months() < seniorityDate.months()) {
        months = endate.months() + (12 - seniorityDate.months());
        years = endate.years() - (seniorityDate.years() + 1);
      } else {
        years = endate.years() - seniorityDate.years();
        months = endate.months() - seniorityDate.months();
      }
      var days = endate.date() - seniorityDate.date();
      var isOneMonth = months == 1 && days >= 0;
      var result = '';
      if (years) {
        if (months == 0 && days < 0) years = years - 1;
        result += i18n.t('common:dateAndTime.frequency.annual', {
          count: years
        });
      }
      if (months > 1 || isOneMonth || months == 0 && days < 0) {
        if (days < 0) months = months >= 1 ? months - 1 : 11;
        if (result) result += ' ';
        result += i18n.t('common:dateAndTime.frequency.monthly', {
          count: months
        });
      }
      if (!result) {
        if (days < 0) {
          days = seniorityDate.daysInMonth() - seniorityDate.date() + endate.date();
        }
        result = i18n.t('common:dateAndTime.frequency.daily', {
          count: days
        });
      }
      return result;
    }),
    // Manage recipients groups for sharing
    addGroup: function addGroup() {
      this.get('groups').createFragment({
        name: '',
        recipients: {}
      });
    },
    deleteGroup: function deleteGroup(group) {
      this.get('groups').removeFragment(group);
    },
    isExternal: Ember.computed('typeOfObj', function () {
      //typeOfObj get to Mixins
      var typeOfObj = this.get('typeOfObj');
      return typeOfObj ? typeOfObj.isExternal : false;
    }),
    hasViewer: Ember.computed('apps.[]', function () {
      var promise = this.get('apps').then(function (apps) {
        return {
          value: apps.isAny('code', viewer_appcode)
        };
      });
      return ObjProxy.create({
        promise: promise
      });
    }),
    hasPostProduction: Ember.computed('apps.[]', function () {
      var promise = this.get('apps').then(function (apps) {
        return {
          value: apps.isAny('code', POSTPRODUCTION_APPCODE)
        };
      });
      return ObjProxy.create({
        promise: promise
      });
    }),
    realWorkUnitRate: Ember.computed('workUnitRate', 'agency.workUnitRate', function () {
      return this.get('workUnitRate') !== _bm.NOT_USED ? this.get('workUnitRate') : this.get('agency.workUnitRate');
    }).readOnly(),
    hasModuleEnabled: function hasModuleEnabled(module) {
      return this.get('hasModule' + Ember.String.capitalize(module) + 'Enabled');
    },
    hasAdvancedRightsEntityAuthorization: function hasAdvancedRightsEntityAuthorization(module, authorization) {
      return this.get('advancedRights.' + module + '.entity.authorizations.' + authorization);
    },
    hasModuleCandidatesEnabled: Ember.computed('advancedRights.candidates.isEnabled', function () {
      return this.get('advancedRights.candidates.isEnabled');
    }),
    hasModuleAppsEnabled: Ember.computed('advancedRights.apps.isEnabled', function () {
      return this.get('advancedRights.apps.isEnabled');
    }),
    hasModuleAppsNoCodeEnabled: Ember.computed('advancedRights.appsNoCode.isEnabled', function () {
      return this.get('advancedRights.appsNoCode.isEnabled');
    }),
    hasModuleResourcesEnabled: Ember.computed('advancedRights.resources.isEnabled', function () {
      return this.get('advancedRights.resources.isEnabled');
    }),
    hasModuleCompaniesEnabled: Ember.computed('advancedRights.companies.isEnabled', function () {
      return this.get('advancedRights.companies.isEnabled');
    }),
    hasModuleContactsEnabled: Ember.computed('advancedRights.contacts.isEnabled', function () {
      return this.get('advancedRights.contacts.isEnabled');
    }),
    hasModuleProductsEnabled: Ember.computed('advancedRights.products.isEnabled', function () {
      return this.get('advancedRights.products.isEnabled');
    }),
    hasModuleOrdersEnabled: Ember.computed('advancedRights.orders.isEnabled', function () {
      return this.get('advancedRights.orders.isEnabled');
    }),
    hasModuleInvoicesEnabled: Ember.computed('advancedRights.invoices.isEnabled', function () {
      return this.get('advancedRights.invoices.isEnabled');
    }),
    hasModuleProjectsEnabled: Ember.computed('advancedRights.projects.isEnabled', function () {
      return this.get('advancedRights.projects.isEnabled');
    }),
    hasModuleResourceProjectsEnabled: Ember.computed('advancedRights.myProjects.isEnabled', function () {
      return this.get('advancedRights.myProjects.isEnabled');
    }),
    hasModuleResourceProviderInvoicesEnabled: Ember.computed('advancedRights.myProviderInvoices.isEnabled', function () {
      return this.get('advancedRights.myProviderInvoices.isEnabled');
    }),
    hasModuleResourceFormsEnabled: Ember.computed('advancedRights.myForms.isEnabled', function () {
      return this.get('advancedRights.myForms.isEnabled');
    }),
    hasModuleOpportunitiesEnabled: Ember.computed('advancedRights.opportunities.isEnabled', function () {
      return this.get('advancedRights.opportunities.isEnabled');
    }),
    hasModulePositioningsEnabled: Ember.computed('advancedRights.positionings.isEnabled', function () {
      return this.get('advancedRights.positionings.isEnabled');
    }),
    hasModuleFlagsEnabled: Ember.computed('advancedRights.flags.isEnabled', function () {
      return this.get('advancedRights.flags.isEnabled');
    }),
    hasModuleActionsEnabled: Ember.computed('advancedRights.actions.isEnabled', function () {
      return this.get('advancedRights.actions.isEnabled');
    }),
    hasModuleSubscriptionEnabled: Ember.computed('advancedRights.subscription.isEnabled', function () {
      return this.get('advancedRights.subscription.isEnabled');
    }),
    hasModuleDownloadCenterEnabled: Ember.computed('advancedRights.downloadCenter.isEnabled', function () {
      return this.get('advancedRights.downloadCenter.isEnabled');
    }),
    hasModuleCustomersEnabled: Ember.computed('advancedRights.customers.isEnabled', function () {
      return this.get('advancedRights.customers.isEnabled');
    }),
    hasModuleArchitectureEnabled: Ember.computed('advancedRights.architecture.isEnabled', function () {
      return this.get('advancedRights.architecture.isEnabled');
    }),
    hasModulePurchasesEnabled: Ember.computed('advancedRights.purchases.isEnabled', function () {
      return this.get('advancedRights.purchases.isEnabled');
    }),
    hasModuleTimesReportsEnabled: Ember.computed('advancedRights.timesReports.isEnabled', function () {
      return this.get('advancedRights.timesReports.isEnabled');
    }),
    hasModuleResourceTimesReportsEnabled: Ember.computed('advancedRights.myTimesReports.isEnabled', function () {
      return this.get('advancedRights.myTimesReports.isEnabled');
    }),
    hasModuleExpensesReportsEnabled: Ember.computed('advancedRights.expensesReports.isEnabled', function () {
      return this.get('advancedRights.expensesReports.isEnabled');
    }),
    hasModuleResourceExpensesReportsEnabled: Ember.computed('advancedRights.myExpensesReports.isEnabled', function () {
      return this.get('advancedRights.myExpensesReports.isEnabled');
    }),
    hasModuleAbsencesReportsEnabled: Ember.computed('advancedRights.absencesReports.isEnabled', function () {
      return this.get('advancedRights.absencesReports.isEnabled');
    }),
    hasModuleResourceAbsencesReportsEnabled: Ember.computed('advancedRights.myAbsencesReports.isEnabled', function () {
      return this.get('advancedRights.myAbsencesReports.isEnabled');
    }),
    hasModuleValidationsEnabled: Ember.computed('advancedRights.validations.isEnabled', function () {
      return this.get('advancedRights.validations.isEnabled');
    }),
    hasModuleReportingProductionPlansEnabled: Ember.computed('advancedRights.reportingProductionPlans.isEnabled', function () {
      return this.get('advancedRights.reportingProductionPlans.isEnabled');
    }),
    hasModulePlanningAbsencesEnabled: Ember.computed('advancedRights.planningAbsences.isEnabled', function () {
      return this.get('advancedRights.planningAbsences.isEnabled');
    }),
    hasModuleReportingSynthesisEnabled: Ember.computed('advancedRights.reportingSynthesis.isEnabled', function () {
      return this.get('advancedRights.reportingSynthesis.isEnabled');
    }),
    hasModuleReportingCompaniesEnabled: Ember.computed('advancedRights.reportingCompanies.isEnabled', function () {
      return this.get('advancedRights.reportingCompanies.isEnabled');
    }),
    hasModuleReportingProjectsEnabled: Ember.computed('advancedRights.reportingProjects.isEnabled', function () {
      return this.get('advancedRights.reportingProjects.isEnabled');
    }),
    hasModuleReportingResourcesEnabled: Ember.computed('advancedRights.reportingResources.isEnabled', function () {
      return this.get('advancedRights.reportingResources.isEnabled');
    }),
    hasModuleReportingEnabled: Ember.computed('hasModuleReportingSynthesisEnabled', 'hasModuleReportingCompaniesEnabled', 'hasModuleReportingProjectsEnabled', 'hasModuleReportingResourcesEnabled', function () {
      return this.get('hasModuleReportingSynthesisEnabled') || this.get('hasModuleReportingCompaniesEnabled') || this.get('hasModuleReportingProjectsEnabled') || this.get('hasModuleReportingResourcesEnabled');
    }),
    hasModulePlanningEnabled: Ember.computed('hasModuleReportingProductionPlansEnabled', 'hasModulePlanningAbsencesEnabled', function () {
      return this.get('hasModuleReportingProductionPlansEnabled') || this.get('hasModulePlanningAbsencesEnabled');
    }),
    hasModuleActivityIntranetEnabled: Ember.computed('advancedRights.activityIntranet.isEnabled', function () {
      return this.get('advancedRights.activityIntranet.isEnabled');
    }),
    hasModuleCrmEnabled: Ember.computed('hasModuleCompaniesEnabled', 'hasModuleContactsEnabled', function () {
      return this.get('hasModuleCompaniesEnabled') || this.get('hasModuleContactsEnabled');
    }),
    hasModuleBillingEnabled: Ember.computed('hasModuleOrdersEnabled', 'hasModuleInvoicesEnabled', function () {
      return this.get('hasModuleOrdersEnabled') || this.get('hasModuleInvoicesEnabled');
    }),
    hasModuleBankingTransactionsEnabled: Ember.computed('advancedRights.bankingTransactions.isEnabled', function () {
      return this.get('advancedRights.bankingTransactions.isEnabled');
    }),
    hasModuleActivityExpensesEnabled: Ember.computed('hasModuleTimesReportsEnabled', 'hasModuleExpensesReportsEnabled', 'hasModuleAbsencesReportsEnabled', 'hasModuleValidationsEnabled', function () {
      return this.get('hasModuleTimesReportsEnabled') || this.get('hasModuleExpensesReportsEnabled') || this.get('hasModuleAbsencesReportsEnabled') || this.get('hasModuleValidationsEnabled');
    }),
    hasModuleImportDataEnabled: Ember.computed('advancedRights.importData.isEnabled', function () {
      return this.get('advancedRights.importData.isEnabled');
    }),
    hasModuleDevelopmentEnabled: Ember.computed('advancedRights.development.isEnabled', function () {
      return this.get('advancedRights.development.isEnabled');
    }),
    hasModuleAccountsEnabled: Ember.computed('advancedRights.accounts.isEnabled', function () {
      return this.get('advancedRights.accounts.isEnabled');
    }),
    hasModuleAgenciesEnabled: Ember.computed('advancedRights.agencies.isEnabled', function () {
      return this.get('advancedRights.agencies.isEnabled');
    }),
    hasModulePolesEnabled: Ember.computed('advancedRights.poles.isEnabled', function () {
      return this.get('advancedRights.poles.isEnabled');
    }),
    hasModuleAlertsEnabled: Ember.computed('advancedRights.alerts.isEnabled', function () {
      return this.get('advancedRights.alerts.isEnabled');
    }),
    hasModuleBusinessUnitsEnabled: Ember.computed('advancedRights.businessUnits.isEnabled', function () {
      return this.get('advancedRights.businessUnits.isEnabled');
    }),
    hasModuleGlobalDataEnabled: Ember.computed('advancedRights.globalData.isEnabled', function () {
      return this.get('advancedRights.globalData.isEnabled');
    }),
    hasModuleWebHooksEnabled: Ember.computed('advancedRights.webHooks.isEnabled', function () {
      return this.get('advancedRights.webHooks.isEnabled');
    }),
    hasModuleMarketplaceEnabled: Ember.computed('advancedRights.marketplace.isEnabled', function () {
      return this.get('advancedRights.marketplace.isEnabled');
    }),
    hasModuleRolesEnabled: Ember.computed('advancedRights.roles.isEnabled', function () {
      return this.get('advancedRights.roles.isEnabled');
    }),
    hasModuleTemplatesActionsEnabled: Ember.computed('advancedRights.templatesActions.isEnabled', function () {
      return this.get('advancedRights.templatesActions.isEnabled');
    }),
    hasModuleTemplatesSavedSearchesEnabled: Ember.computed('advancedRights.templatesSavedSearches.isEnabled', function () {
      return this.get('advancedRights.templatesSavedSearches.isEnabled');
    }),
    hasModuleTemplatesTodoListsEnabled: Ember.computed('advancedRights.templatesTodoLists.isEnabled', function () {
      return this.get('advancedRights.templatesTodoLists.isEnabled');
    }),
    hasModuleTemplatesRolesEnabled: Ember.computed('advancedRights.templatesRoles.isEnabled', function () {
      return this.get('advancedRights.templatesRoles.isEnabled');
    }),
    hasModuleTemplatesFormsEnabled: Ember.computed('advancedRights.templatesForms.isEnabled', function () {
      return this.get('advancedRights.templatesForms.isEnabled');
    }),
    hasModuleTemplatesEnabled: Ember.computed('hasModuleTemplatesActionsEnabled', 'hasModuleTemplatesSavedSearchesEnabled', 'hasModuleTemplatesTodoListsEnabled', 'hasModuleTemplatesRolesEnabled', 'hasModuleTemplatesFormsEnabled', function () {
      return this.get('hasModuleTemplatesActionsEnabled') || this.get('hasModuleTemplatesSavedSearchesEnabled') || this.get('hasModuleTemplatesTodoListsEnabled') || this.get('hasModuleTemplatesRolesEnabled') || this.get('hasModuleTemplatesFormsEnabled');
    }),
    hasModuleLogsEnabled: Ember.computed('advancedRights.logs.isEnabled', function () {
      return this.get('advancedRights.logs.isEnabled');
    }),
    hasModuleAdministrationEnabled: Ember.computed('isAdministrator', 'isOwner', 'hasModuleAccountsEnabled', 'hasModuleCustomersEnabled', 'hasModuleArchitectureEnabled', 'hasModuleRolesEnabled', 'hasModuleAgenciesEnabled', 'hasModuleBusinessUnitsEnabled', 'hasModuleImportDataEnabled', 'hasModuleGlobalDataEnabled', 'hasModuleDevelopmentEnabled', 'hasModuleMarketplaceEnabled', 'hasModulePolesEnabled', 'hasModuleWebHooksEnabled', 'hasModuleTemplatesActionsEnabled', 'hasModuleTemplatesSavedSearchesEnabled', 'hasModuleTemplatesTodoListsEnabled', 'hasModuleTemplatesRolesEnabled', 'hasModuleTemplatesFormsEnabled', 'hasModuleAlertsEnabled', 'hasModuleBankingConnectionsEnabled', function () {
      return this.get('isAdministrator') || this.get('isOwner') || this.get('hasModuleAccountsEnabled') || this.get('hasModuleCustomersEnabled') || this.get('hasModuleArchitectureEnabled') || this.get('hasModuleTemplatesRolesEnabled') || this.get('hasModuleRolesEnabled') || this.get('hasModuleAgenciesEnabled') || this.get('hasModuleBusinessUnitsEnabled') || this.get('hasModuleImportDataEnabled') || this.get('hasModuleGlobalDataEnabled') || this.get('hasModuleDevelopmentEnabled') || this.get('hasModuleMarketplaceEnabled') || this.get('hasModulePolesEnabled') || this.get('hasModuleWebHooksEnabled') || this.get('hasModuleTemplatesActionsEnabled') || this.get('hasModuleTemplatesSavedSearchesEnabled') || this.get('hasModuleTemplatesTodoListsEnabled') || this.get('hasModuleTemplatesRolesEnabled') || this.get('hasModuleTemplatesFormsEnabled') || this.get('hasModuleAlertsEnabled') || this.get('hasModuleBankingConnectionsEnabled');
    }),
    hasModuleBankingConnectionsEnabled: Ember.computed('advancedRights.bankingConnections.isEnabled', function () {
      return this.get('advancedRights.bankingConnections.isEnabled');
    }),
    hasSharingRights: Ember.computed('advancedRights.sharedEntities.entity.authorizations.shareMySharedEntities', function () {
      return this.get('advancedRights.sharedEntities.entity.authorizations.shareMySharedEntities');
    }),
    hasSharingAndEditRights: Ember.computed('advancedRights.sharedEntities.entity.authorizations.editAndShareMySharedEntities', function () {
      return this.get('advancedRights.sharedEntities.entity.authorizations.editAndShareMySharedEntities');
    }),
    isMainManagerAssignmentEmptyAllowedForModuleAccounts: Ember.computed('isAdministrator', 'isOwner', 'advancedRights.accounts.entity.fields.{[],@each.assignments.empty}', function () {
      var field = this.get('advancedRights.accounts') && this.get('advancedRights.accounts.entity.fields').find(function (item) {
        return item.name === 'mainManager';
      });
      return this.get('isAdministrator') || this.get('isOwner') || field && field.assignments.empty;
    }),
    isRoleAssignmentEmptyAllowedForModuleAccounts: Ember.computed('isAdministrator', 'isOwner', 'advancedRights.accounts.entity.fields.{[],@each.assignments.empty}', function () {
      var field = this.get('advancedRights.accounts') && this.get('advancedRights.accounts.entity.fields').find(function (item) {
        return item.name === 'role';
      });
      return this.get('isAdministrator') || this.get('isOwner') || field && field.assignments.empty;
    }),
    dynamicTheme: Ember.computed('theme', function () {
      var theme = this.get('theme');
      if (theme == SYSTEM_USER_THEME) {
        theme = this.get('systemTheme').getCurrentTheme();
      }
      return theme;
    }),
    alertsList: Ember.computed('alerts.[]', function () {
      return this.get('alerts').toArray();
    }).readOnly(),
    alertsListForResource: Ember.computed('alerts.[]', function () {
      return this.get('alerts').filter(function (item) {
        return !item.get('isAdministrator') || item.get('canReadAlert') === true;
      }).toArray();
    }).readOnly(),
    initAbsences: function initAbsences(calendar, absencesReports) {
      var validatedDurations = [];
      var unvalidatedDurations = [];
      var absencesByDay = [];
      var totalAbsencesDurations = 0;
      absencesReports.filter(function (absenceReport) {
        return [_baseactivity.ACTIVITY_VALIDATED, _baseactivity.ACTIVITY_WAITING_FOR_VALIDATION].includes(absenceReport.get('state'));
      }).forEach(function (absenceReport) {
        absenceReport.get('absencesPeriods').forEach(function (absencePeriod) {
          calendar.forEach(function (currentDay) {
            if (currentDay.date.isSameOrAfter(absencePeriod.get('startDate')) && currentDay.date.isSameOrBefore(absencePeriod.get('endDate'))) {
              var duration = (0, _activity.getDuration)(absencePeriod.get('startDate'), absencePeriod.get('endDate'), currentDay.date, absencePeriod.get('duration'));
              var dateKey = currentDay.date.format('YYYYMMDD');
              var isValidatedAbsence = absenceReport.get('state') === _baseactivity.ACTIVITY_VALIDATED;
              var isUnvalidatedAbsence = absenceReport.get('state') === _baseactivity.ACTIVITY_WAITING_FOR_VALIDATION;

              // On cumule les durées des absences pour chaque jour du calendrier
              if (isValidatedAbsence) {
                if (!validatedDurations[dateKey]) validatedDurations[dateKey] = 0;
                validatedDurations[dateKey] += duration;
              } else if (isUnvalidatedAbsence) {
                if (!unvalidatedDurations[dateKey]) unvalidatedDurations[dateKey] = 0;
                unvalidatedDurations[dateKey] += duration;
              }
              totalAbsencesDurations += duration;
              if (isValidatedAbsence || isUnvalidatedAbsence) {
                if (!absencesByDay[dateKey]) absencesByDay[dateKey] = [];
                absencesByDay[dateKey].push({
                  absenceReport: absenceReport,
                  absencePeriod: absencePeriod,
                  duration: duration
                });
              }
            }
          });
        });
      });
      this.set('validatedAbsencesDurationsByDay', validatedDurations);
      this.set('unvalidatedAbsencesDurationsByDay', unvalidatedDurations);
      this.set('absencesByDay', absencesByDay);
      this.set('totalAbsencesDurations', totalAbsencesDurations);
    }
  });
  Resource.reopenClass({
    prefix: 'COMP'
  });
  Resource.reopen({
    prefix: Ember.computed(function () {
      return Resource.prefix;
    }).readOnly()
  });
  var _default = _exports.default = Resource;
});