define("boondmanager/models/bankinguser", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable"], function (_exports, _base, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var BankingUser = _base.default.extend(_emberDataCopyable.default, {
    providerId: _emberData.default.attr('string'),
    tempToken: _emberData.default.attr('string')
  });
  BankingUser.reopenClass({
    prefix: 'BNKUSR'
  });
  BankingUser.reopen({
    prefix: Ember.computed(function () {
      return BankingUser.prefix;
    }).readOnly()
  });
  var _default = _exports.default = BankingUser;
});