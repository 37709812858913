define("boondmanager/pods/components/octane/bm-field/select/selected/colors/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jzLg1pyY",
    "block": "{\"symbols\":[\"@fieldname\",\"@selected\"],\"statements\":[[10,\"div\"],[14,0,\"bmc-field-simple-colorpicker-selected-item\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"bmc-field-simple-colorpicker-fieldname\"],[12],[1,[32,1]],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"bmc-field-simple-colorpicker-color\"],[15,5,[30,[36,1],[[30,[36,0],[\"background: \",[32,2,[\"value\"]],\";\"],null]],null]],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"concat\",\"html-safe\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/select/selected/colors/template.hbs"
  });
});